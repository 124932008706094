<template>
  <div class="tasks-panel">
    <div class="card-body">
      <ul class="list-group">
        <template v-for="item in todos">
          <router-link tag="li" :to="item.route" class="list-group-item pointer" :key="item.id">
            <span :style="iconStyle">
              <button :aria-label="item.title" class="material-icons float-left mr-1">chevron_right</button>
            </span>
            <span class="title"> {{ item.title }}</span>
          </router-link>
        </template>
        <router-link tag="li" :to="{ name: 'tasks' }" class="list-group-item pointer">
          <primary-button class="btn-sm">
            <i class="material-icons float-left mr-1" :style="{ color: style.colorPrimaryButtonText }">chevron_right</i>
            {{ 'general.showAll' | translate }}
          </primary-button>
        </router-link>
      </ul>
      <div class="text-center mt-4 mb-4" v-if="!todos.length">
        <i class="material-icons done" :style="iconStyle">check</i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import tasks from '@app/mixins/tasks';

export default {
  mixins: [tasks],
  computed: {
    ...mapGetters('settings', ['style']),
    headerStyle() {
      return {
        color: this.style.taskHeadingTextColor
      };
    },
    iconStyle() {
      return {
        color: this.style.taskIconColor
      };
    },
    borderTodoStyle() {
      return this.todo
        ? {
            'box-shadow': 'inset 0 -2px 0 ' + this.style.taskHeadingTextColor
          }
        : {};
    }
  }
};
</script>

<style lang="scss">
.tasks-panel .el-tabs__header {
  margin: 0 !important;
}
.tasks-panel .el-tabs__nav {
  width: 100%;
  .el-tabs__item {
    width: 50% !important;
    text-align: center;
  }
  .el-tabs__active-bar {
    width: 50% !important;
  }
}
</style>

<style lang="scss" scoped>
.tasks-panel {
  overflow-y: auto;
  // padding-top: 42px;
  max-height: 100%;

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 300px;
  }
}

.tasks-panel .card-body {
  padding: 0;
}

.tasks-panel ul.nav-tabs {
  position: absolute;
  top: 0;
  width: calc(100% - 45px);
  z-index: 2;
}

.tasks-panel ul {
  margin-bottom: 0;
}
ul.nav-tabs > li {
  font-weight: bold;
  width: 50%;
  background: white;

  > a {
    color: inherit;
    &:hover {
      box-shadow: inherit;
      color: inherit;
    }
  }
}

li.list-group-item {
  border-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #e9ecef;
  border-color: #e9ecef !important;
  margin-top: 1px;

  &.active {
    background-color: white;
    border-color: inherit;
    color: inherit;
  }

  .badge-pill {
    background-color: inherit;
  }

  .material-icons {
    font-size: 20px;
  }
}
li.list-group-item:last-of-type {
  border-bottom: 1px solid lightgrey;
  border-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

ul.list-group {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow-y: auto;
  font-size: 0.9rem;
}
li.list-group-item span {
  button {
    background: none;
    border: none;
  }
}
.material-icons.done {
  font-size: 60px;
}
</style>
